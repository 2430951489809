<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <ProjectInfo />
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div
              v-for="change in changes"
              :key="change"
              style="padding-bottom: 30px;"

            >
              <Accordion
                :activeIndex="1"

              >
                <AccordionTab
                  :class="{prod: change['description']['organism'] == 'Produtividade'}"
                >
                  <template
                    style="
                      display: flex;
                      align-items: flex-start;
                      justify-content: space-between;
                    "
                    #header
                  >
                    <div style="display: flex; justify-content: space-between">
                      <div
                        style="
                          align-self: flex-start;
                          padding-top: 10px;
                          padding-left: 30px;
                        "
                      >
                        <span style="font-weight: 600; font-size: 18px">{{
                          change.description.organism
                        }}</span>
                      </div>
                      <div
                        style="
                          display: flex;
                          place-items: center;
                          width: max-content;
                          margin-left: 20px;
                          padding-right: 30px;
                          padding-left: 30px;
                          background: #ffffff;
                          box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                          border-radius: 17px;
                          align-self: flex-end;
                        "
                      >
                        <Image
                          v-if="
                            change.description.variation.substr(0, 1) != '-'
                          "
                          src="/images/positiveIcon.svg"
                          alt="Image"
                          width="30"
                          style="padding-top: 16px"
                        />
                        <Image
                          v-else
                          src="/images/negativeIcon.svg"
                          alt="Image"
                          width="30"
                          style="padding-top: 16px"
                        />
                        <p
                          style="
                            font-style: normal;
                            font-weight: 900;
                            font-size: 25px;
                            line-height: 47px;
                            color: black;
                          "
                        >
                          {{ change.description.variation }}
                        </p>
                      </div>
                    </div>
                  </template>

                  <div class="grid" >
                    <div
                      v-for="result in change.areaResults"
                      :key="result.name"
                      class="col-12 xl:col-4 md:col-4 lg:col-12 sm:col-12 xs:col-12"
                    >
                      <CardInfo :result="result" :project="project" />
                    </div>
                  </div>

                    <template v-if="change.description.organism == 'Produtividade'">
                                         <div class="card grid col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 xs:col-12"  style="margin-top: 10px !important;">
                          <p
                            style="
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 23px;
                            color: #585857;
                            padding-top: 25px;
                            text-align: center;"> 
                      Coeficiente de correlação (R) de benefícios secundários associados com aumento da produtividade 
                    </p>
                    <br>

                    <div class="grid col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 xs:col-12" style="margin-top: 10px !important;">
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Fixação de nitrogenio <br> <p style="color: black;">R = 0.6</p></div>
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Controle de fungos fitopatogênicos <br> <p style="color: black;">R = 0.45</p></div>
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Solubilização de fósforo <br> <p style="color: black;">R = 0.56</p></div>
                    
                  </div>
                                    </div>

                </template>
                <template v-if="change.description.organism == 'Concentração de fósforo'">
                                     <div class="card grid col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 xs:col-12"  style="margin-top: 10px !important; align-items: center;">
                          <p
                            style="
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 23px;
                            color: #585857;
                            padding-top: 25px;
                            text-align: center;"> 
                      Coeficiente de correlação (R) de benefícios secundários associados com aumento da concentração de fósforo 
                    </p>
                    <br>

                    <div class="grid col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 xs:col-12" style="margin-top: 10px !important;">
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Fixação de nitrogenio <br> <p style="color: black;">R = 0.3</p></div>
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Solubilização de fósforo <br> <p style="color: black;">R = 0.8</p></div>
                    
                  </div>
                                    </div>

                </template>
                <template v-if="change.description.organism == 'Concentração de nitrogênio'">
                                     <div class="card grid col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 xs:col-12"  style="margin-top: 10px !important;">
                          <p
                            style="
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 23px;
                            color: #585857;
                            padding-top: 25px;
                            text-align: center;"> 
                      Coeficiente de correlação (R) de benefícios secundários associados com aumento da concentração de nitrogênio 
                    </p>
                    <br>

                    <div class="grid col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 xs:col-12" style="margin-top: 10px !important;">
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Fixação de nitrogenio <br> <p style="color: black;">R = 0.8</p></div>
                    <div class="card col-4" style="font-style: normal; font-weight: 600; font-size: 15px; line-height: 18px; color: #466107">Solubilização de fósforo <br> <p style="color: black;">R = 0.4</p></div>
                    
                  </div>
                                    </div>

                </template>

                  <div v-for="chart in change.chartData" :key="chart">
                    <ChartError
                      :data="chart"
                      :description="change.description"
                      :filterType="'basic'"
                      :chartType="'basic'"
                    />
                  </div>

                 
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/LeafCorrelation/BodyHeader.vue";
import CardInfo from "@/components/LeafCorrelation/CardInfo.vue";
import ChartError from "@/components/Generic/ChartError.vue";
import { useProjectsStore } from "@/store/projects";
import { changes } from "@/assets/Data/LeafCorrelationData";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      i: 1,
      chartOptions: {
        palette: [
          "#7CBAB4",
          "#92C7E2",
          "#75B5D6",
          "#B78C9B",
          "#F2CA84",
          "#A7CA74",
        ],
      },
      changes,
      selectedType: null,
      loading: true,
      displayModal: false,
      filterarea: "AllAreas",
      filterType: "Controle",
      filterType2: "Vegetativo",
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {},
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ChartError: ChartError,
    CardInfo: CardInfo,
  },
};
</script>

<style>
</style>