<template>
  <div class="grid" style="padding-top: 20px">
    <div class="col-12 xl:col-12 sm:col-12 .col-12">
      <h3
        style="
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 140.62%;
          color: rgba(0, 0, 0, 0.7);
        "
      >
        Produtividade e correlação foliar
      </h3>
      <h4
        style="
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          color: #585857;
        "
      >
        Nesta seção apresentamos os nutrientes que mostraram uma concentração
        maior nas plantas onde o Produto foi aplicado. Apenas os nutrientes com
        diferença estatística significativa, filtrados pelo valor de p &lt; 0,05,
        são apresentados. São apresentados as espécies e gêneros associados com
        o aumento do nutriente que tiveram diferença estatística significativa
        (p &lt; 0,05) entre o tratamento com Produto e o Controle.
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
