export const changes = [
  {
    description: {
      organism: "Produtividade",
      variation: "2,4x",
    },

    areaResults: [
      {
        id: 1,
        name: "Área 1",
        location: "Londrina, PR",
        variationArea: "0,0x",
        measure: "g/Kg M.S",
        controle: "55,3 ± 0,1 sc/ha",
        produto: "55,2 ± 0,3 sc/ha",
      },
      {
        id: 2,
        name: "Área 2",
        location: "Arapongas, PR",
        variationArea: "1,2x",
        measure: "g/Kg M.S",
        controle: "61,2 ± 0,3 sc/ha",
        produto: "75,6 ± 0,7 sc/ha",
      },
      {
        id: 3,
        name: "Área 3",
        location: "Maringá, PR",
        variationArea: "1,1x",
        measure: "g/Kg M.S",
        controle: "59,3 ± 0,2 sc/ha",
        produto: "64,2 ± 0,3 sc/ha",
      },
    ],

    chartData: [
      {
        Produtividade: {
          bacterias: [

    {
  type: 'Bacillus',
  avgH: 3.1,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 1.5,
},
{
  type: 'Azospirillum brasiliense',
  avgH: 5.2,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 0,
},
{
type: 'Azospirillum genomospecies',
  avgH: 2.1,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 0.5,
},
{
type: 'Bradyrhizobium',
  avgH: 9.6,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 5.5,
},
{
type: 'Acetobacter',
  avgH: 15.5,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 6.9,
}

  ],
          fungus: [
   {
      type: 'Trichoderma',
      avgH: 6.2,
      avgLowT: 9.8,
      avgHighT: 19.6,
      avgT: 5.5,
    }, {
      type: 'Curvularia',
      avgH: 2.1,
      avgLowT: 10.6,
      avgHighT: 20.4,
      avgT: 1.2,
    },
    {
      type: 'Glomus',
      avgH: 4.2,
      avgLowT: 10.6,
      avgHighT: 20.4,
      avgT: 2.1,
    }
  ],
        },
      },
    ],
  },
{
  
    description: {
      organism: "Concentração foliar de fósforo",
      variation: "2,4x",
    },

    areaResults: [
      {
        id: 1,
        name: "Área 1",
        location: "Londrina, PR",
        variationArea: "0,0x",
        measure: "g/Kg M.S",
        controle: "2,8 ± 0,2 g/Kg M.S",
        produto: "2,8 ± 0,5 g/Kg M.S",
      },
      {
        id: 2,
        name: "Área 2",
        location: "Arapongas, PR",
        variationArea: "2,4x",
        measure: "g/Kg M.S",
        controle: "2,1 ± 0,3 g/Kg M.S",
        produto: "2,6 ± 0,7 g/Kg M.S",
      },
      {
        id: 3,
        name: "Área 3",
        location: "Maringá, PR",
        variationArea: "2,1x",
        measure: "g/Kg M.S",
        controle: "2,5 ± 0,2 g/Kg M.S",
        produto: "2,9 ± 0,5 g/Kg M.S",
      },
    ],

    chartData: [
      {
        "Concentração foliar de fósforo": {
          bacterias: [

    {
  type: 'Bacillus',
  avgH: 3.1,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 1.5,
},
{
  type: 'Azospirillum brasiliense',
  avgH: 5.2,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 0,
},
{
type: 'Azospirillum genomospecies',
  avgH: 2.1,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 0.5,
},
{
type: 'Paenibacillus',
  avgH: 9.0,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 5.0,
},
{
type: 'Acetobacter',
  avgH: 15.5,
  avgLowT: 10.6,
  avgHighT: 20.4,
  avgT: 6.9,
}


  ],
          fungus: [
{
      type: 'Trichoderma',
      avgH: 6.2,
      avgLowT: 10.6,
      avgHighT: 20.4,
      avgT: 5.5,
    },
    {
      type: 'Glomus',
      avgH: 4.2,
      avgLowT: 10.6,
      avgHighT: 20.4,
      avgT: 2.1,
    }
  ],
        },
      },
    ],
  
    },
  {
    description: {
      organism: "Concentração foliar de nitrogênio",
      variation: "2,2x",
    },

    areaResults: [
        {
            id: 1,
            name: "Área 1",
            location: "Londrina, PR",
            variationArea: "1,2x",
            measure: "g/Kg M.S",
            controle: "36,8 ± 0,6 g/Kg M.S",
            produto: "42,2 ± 0,6 g/Kg M.S",
          },
          {
            id: 2,
            name: "Área 2",
            location: "Arapongas, PR",
            variationArea: "1,2x",
            measure: "g/Kg M.S",
            controle: "41,2 ± 0,2 g/Kg M.S",
            produto: "46,2 ± 0,5 g/Kg M.S",
          },
          {
            id: 3,
            name: "Área 3",
            location: "Maringá, PR",
            variationArea: "1,3x",
            measure: "g/Kg M.S",
            controle: "36,0 ± 0,2 g/Kg M.S",
            produto: "45,0 ± 0,4 g/Kg M.S",
          },
    ],

    chartData: [
      {
        "Concentração foliar de nitrogênio": {
          bacterias: [

    {
  type: 'Rhizobium rhizoryzae',
  avgH: 2.5,
  avgLowT: 9.1,
  avgHighT: 19.1,
  avgT: 0,
}, {
  type: 'Bradyrhizobium',
  avgH: 5.5,
  avgLowT: 9.8,
  avgHighT: 19.6,
  avgT: 5,
},
    {
  type: 'Azospirillum brasiliense',
  avgH: 5.2,
  avgLowT: 9.1,
  avgHighT: 19.1,
  avgT: 0,
}, {
  type: 'Acetobacter',
  avgH: 55.5,
  avgLowT: 9.8,
  avgHighT: 19.6,
  avgT: 6.9,
}
  ],
          fungus: [
          
    {
      type: 'Glomus',
      avgH: 4.2,
      avgLowT: 10.6,
      avgHighT: 20.4,
      avgT: 2.1,
    }
  ],
        },
      },
    ],
  },
];
