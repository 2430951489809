<template>
  <div
    style="
      background: #ffffff;
      border: 3px solid #f5f6f7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 17px;
      padding-bottom: 15px;
    "
  >
    <div style="display: flex; justify-content: flex-end">
      <div class="image">
        >
        <Image src="/images/locIcon.svg" alt="Image" width="25" />
      </div>
    </div>
    <div class="block" style="justify-content: center; margin-top: -8%">
      <h4 class="area">{{ result.name }}</h4>
      <span class="areaname">{{ result.location }}</span>
    </div>

    <div
        class="col-12 xl:col-12 md:col-5 lg:col-12 sm:col-12 .col-12"
      >
        <div class="imageIcon">
          <Image
            v-if="result.variationArea === '0,0x'"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/communIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else-if="result.variationArea.substr(0, 1) != '-'"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/positiveIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/negativeIcon.svg"
            alt="Image"
            width="25"
          />
          <div
            v-if="result.variationArea === '0,0x'"
            style="width: 99px; height: 28px; background: #979797"
          >
            <p style="font-size: 9px">Sem alteração</p>
          </div>
          <div v-else>
            <p class="results">
              {{ result.variationArea }}
            </p>
          </div>
        </div>
      </div>

    <div class="grid" style="justify-content: center; margin-top: 20px">
      <div
        style="padding-right: 20px"
        class="col-12 xl:col-5 md:col-5 lg:col-12 sm:col-12 .col-12"
      >
        <div style="display: flex; justify-content: center">
          <Image
            src="/images/control.svg"
            alt="Image"
            width="30"
            style="padding-right: 10px"
          />
          <span class="areatype">Controle</span>
        </div>
        <div class="imageIcon">
          <div
            v-if="result.controle === '0,0x'"
            style="width: 99px; height: 28px; background: #979797"
          >
            <p style="font-size: 9px">Sem alteração</p>
          </div>
          <div v-else>
            <p class="results">
              {{ result.controle }}
            </p>
          </div>
        </div>
      </div>
      <div
        style="padding-left: 20px"
        class="col-12 xl:col-5 md:col-5 lg:col-12 sm:col-12 .col-12"
      >
        <div style="display: flex; justify-content: center">
          <Image
            src="/images/product.svg"
            alt="Image"
            width="30"
            style="padding-right: 10px"
          />
          <span class="areatype">Produto</span>
        </div>
        <div class="imageIcon">
          <div
            v-if="result.produto === '0,0x'"
            style="width: 99px; height: 28px; background: #979797"
          >
            <p style="font-size: 9px">Sem alteração</p>
          </div>
          <div v-else>
            <p class="results">
              {{ result.produto }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
  },
  props: {
    result: Object,
    project: Object,
  },
};
</script>

<style></style>
